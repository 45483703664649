<template>
  <section class="sitemap">
    <div class="container">
      <h1 class="text-center">Sitemap</h1>
      <div class="row pt-4">
        <div class="col-lg-2 col-md-2 col-12">
          <h3 class="pt-2"><a href="../">Home</a></h3>
          <p></p>
        </div>
        <div class="col-lg-3 col-md-3 col-12">
          <h3 class="pt-2" style="font-weight: 600">
            <a href="../">BI & Analytics</a>
          </h3>
          <p class="pt-2"><a href="">Business Intelligence</a></p>
          <p><a href="">Embedded Analytics</a></p>
          <p><a href="">Data Analytics</a></p>
          <p><a href="">Data Visualization</a></p>
          <p><a href="">Insights & Reports</a></p>
        </div>
        <div class="col-lg-3 col-md-3 col-12">
          <h3 class="pt-2" style="font-weight: 600">
            <a href="../">Resources</a>
          </h3>
          <p class="pt-2"><a href="">Show Cases</a></p>
          <p><a href="">Blogs</a></p>
          <p><a href="">Newsletter</a></p>
          <p><a href="">Webinars</a></p>
        </div>
        <div class="col-lg-2 col-md-2 col-12">
          <h3 class="pt-2" style="font-weight: 600">
            <a href="../">Company</a>
          </h3>
          <p class="pt-2"><a href="">About</a></p>
          <p><a href="../contact-us">Contact Us</a></p>
          <p><a href="">Partners</a></p>
          <p><a href="">Careers</a></p>
          <p><a href="">Security</a></p>
          <p><a href="">Compliance</a></p>
        </div>
        <div class="col-lg-2 col-md-2 col-6">
          <h3 class="pt-2" style="font-weight: 600">
            <a href="../">Policy</a>
          </h3>
          <p class="pt-2"><a href="">Terms of Service</a></p>
          <p><a href="">Privacy Policy</a></p>
          <p><a href="">Cookies Settings</a></p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
};
</script>
<style>
.sitemap {
  padding: 130px 40px 30px 40px;
}
@media only screen and (min-width: 0px) and (max-width: 998px) {
  .sitemap {
    padding: 40px !important;
  }
}
</style>
