<template>
  <Header />
  <SiteMap />
  <Footer />
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SiteMap from "@/components/SiteMap.vue";

export default {
  components: {
    Header,
    Footer,
    SiteMap,
  },
};
</script>
